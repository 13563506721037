@import "./Variables.scss";

.kit-dropdown {
  padding: 0em;

  // &:after,
  // &:before {
  //   bottom: 100%;
  //   right: 5px;
  //   border: solid transparent;
  //   content: " ";
  //   height: 0;
  //   width: 0;
  //   position: absolute;
  //   pointer-events: none;
  // }

  // &:before {
  //   border-bottom-color: rgba(166, 168, 179, 0.2);
  //   border-width: 8px;
  //   margin-left: -8px;
  // }

  // &:after {
  //   border-bottom-color: #fff;
  //   border-width: 6px;
  //   margin-left: -6px;
  // }

  .kit-dropdown-nav {
    padding: 0;

    li {
      margin: 0;
      &:not(:last-child) {
        border-bottom: 1px solid $muted-color;
      }

      .kit-dropdown-btn {
        display: flex;
        align-items: center;
        margin: 0;
        padding: 0.8em 1em;
        width: 100%;
        background-color: transparent;
        border: 0;
        outline: none;
        min-height: 0 !important;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        max-width: 200px;
        font-size: $font-size-sm;
        font-weight: $font-weight-semibold;
        text-align: left;
        white-space: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        color: $dark-grey-color;

        span {
          min-width: 20px;
          margin-right: 5px;
        }

        &:hover {
          background-color: $hover-color;
          color: $black-color;
        }

        &:disabled {
          color: $grey-color !important;
          cursor: not-allowed;

          .icon {
            // background-color: $grey-color;
          }

          &:hover {
            background-color: $muted-color !important;
          }
        }
      }
    }
  }
}
