@import "./Variables.scss";

.kit-card {
  border-radius: $border-radius-md;
  overflow: hidden;

  &.kit-card-default {
    background-color: $color-white;
    border: 1px solid $color-muted;
    padding: 1rem 1.5rem;
    transition: box-shadow 0.3s ease-in-out;
    box-shadow: 0 3px 4px rgba(#000, 0.1);

    &:hover {
      box-shadow: 0 12px 16px rgba(#000, 0.1);
    }
  }
}
