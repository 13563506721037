@import "./Variables.scss";

// Buttons
button {
  border: 0;
}

// filled-buttons
.btn {
  display: inline-block;
  padding: 0.5em 1em;
  border-radius: $border-radius;
  font-size: $font-size;
  font-weight: $font-weight-semibold;
  color: $white-color;
  background-color: $muted-color;

  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 0 3rem rgba(0, 0, 0, 0.1);
  }

  //primary
  &.btn-primary {
    border: 1px solid $primary-color;
    background-color: $primary-color;
    color: $white-color;
    &:hover {
      box-shadow: 0 10px 14px rgba($primary-color, 0.4);
    }
  }

  &.btn-danger {
    border: 1px solid $danger-color;
    background-color: $danger-color;
    color: $white-color;
    &:hover {
      box-shadow: 0 10px 14px rgba($danger-color, 0.4);
    }
  }

  &:disabled {
    opacity: 0.5;
    background-color: $grey-color;
    border: 1px solid $grey-color;
    cursor: not-allowed;
    &:hover {
      box-shadow: 0 10px 14px rgba($grey-color, 0.4);
    }
  }
}

.btn-outlined {
  display: inline-block;
  padding: 0.5em 1em;
  font-size: $font-size;
  font-weight: $font-weight-semibold;
  color: $black-color;
  border-radius: $border-radius;
  border: 1px solid $primary-color;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: $white-color !important;
    background-color: $primary-color;
    box-shadow: 0 0 3rem rgba(0, 0, 0, 0.1);
  }

  //primary
  &.btn-primary {
    color: $primary-color;
  }
  // secondary
  &.btn-secondary {
    color: $muted-color;
  }
  // disabled
  &.btn-disabled {
    color: $grey-color;
    cursor: not-allowed;
  }
}

// text-buttons
.btn-text {
  display: inline-block;
  padding: 0.5em 1em;
  border-radius: $border-radius;
  font-size: $font-size-sm;
  font-weight: $font-weight;
  color: $black-color;
  background-color: transparent;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  //  primary
  &.btn-primary {
    color: $primary-color;
  }
  // secondary
  &.btn-secondary {
    color: $white-color;
  }
  // disabled
  &.btn-disabled {
    color: $grey-color;
    cursor: not-allowed;
  }
  // danger
  &.btn-danger {
    color: $danger-color;
  }
}

// outline-buttons

// icon-buttons {
.btn-icon {
  display: inline-block;
  padding: 0;
  border-radius: 0;
  font-size: $font-size-sm;
  font-weight: $font-weight;
  color: $black-color;
  background-color: transparent;
  min-width: 25px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  //  primary
  &.btn-primary {
    // color: $white-color;
  }
  // secondary
  &.btn-secondary {
    // color: $white-color;
  }
  // disabled
  &.btn-disabled {
    color: $grey-color;
    cursor: not-allowed;
  }
}

// Back button special
.kit-btn-back {
  display: flex;
  align-items: center;
  background-color: transparent;
  color: $secondary-btn;
  line-height: 1;
  margin: 0;

  span {
    transition: 0.3s all ease-in-out;
  }

  &:hover {
    cursor: pointer;
    span {
      margin-right: 5px;
    }
  }
}
