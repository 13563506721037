@import "./Variables.scss";
@import "./Kit.scss";

.section-header {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__sub-title {
    font-size: $font-size-md;
    font-weight: $font-weight-semi-bold;
    text-transform: uppercase;
    line-height: 1.2;
    margin-bottom: 0.2rem;
    color: $color-white;
    background-color: $color-dark;
    padding: 0.5em 1em;
  }

  &__title {
    font-size: $font-size-3xl;
    font-weight: $font-weight-bold;
    line-height: 1.2;
    margin-top: 0.2rem;
    margin-bottom: 1.4rem;
    max-width: 50vmax;
  }

  &__message {
    font-size: $font-size-xl;
    font-weight: $font-weight-normal;
    line-height: 1.2;
    margin-top: 0.2rem;
    margin-bottom: 3rem;
    max-width: 60vmax;
    color: $color-secondary;
  }
}

.navbar-section {
  background-color: $color-primary;

  .uk-navbar-sticky {
    box-shadow: 0 0.1rem 1rem rgba($color-dark, 0.1);
    background-color: $color-white;
  }

  nav {
    .brand {
      max-height: 100px;
    }
    li a {
      text-transform: none;
      font-size: $font-size-lg;
      font-weight: $font-weight-semi-bold;
    }
  }
}

.header-section {
  background-color: $color-primary;
  padding-top: 6rem;
  padding-bottom: 6rem;

  &__title {
    font-size: $font-size-3xl;
    font-weight: $font-weight-bold;
    text-transform: capitalize;
    line-height: 1.4;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  &__message {
    font-size: $font-size-lg;
    font-weight: $font-weight-normal;
    color: $color-white;
    line-height: 1.5;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
  }

  .controls {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 4rem;

    a {
      font-size: $font-size-lg;
      text-decoration: none;
      padding: 0.8rem 1.6rem;
      margin-bottom: 1rem;
    }

    .get-started {
      color: $color-primary;
      padding: 10px 30px;
    }

    .play-btn {
      &:hover {
        .icon {
          margin-right: 10px;
          transform: translate(0, -3px);
          color: $color-primary;
        }
      }
      .icon {
        color: $color-white;
        border-radius: 50%;
        margin-right: 5px;
        transition: all 0.3s ease-in-out;
      }
    }
  }
  .img-container {
    margin-top: 30px;
    img {
      width: 100%;
      height: auto;
      // object-fit: cover;
    }
  }
}

.functionality-section {
  .functionality-card {
    // padding: 3em;
    &__icon {
      text-align: center;
      line-height: 50px;
      height: 50px;
      width: 50px;
      background-color: $color-primary;
      border-radius: 5px;
      color: $color-white;
      box-shadow: 0 0.2rem 1rem rgba($color: $color-primary, $alpha: 0.6);
    }

    &__title {
      font-size: $font-size-lg;
      font-weight: $font-weight-bold;
      text-transform: uppercase;
      line-height: 1.5;
      margin-top: 1.2rem;
      margin-bottom: 0.4rem;
    }

    &__message {
      font-size: $font-size-md;
      font-weight: $font-weight-normal;
      line-height: 1.5;
      margin-top: 0.4rem;
      margin-bottom: 2rem;
    }
  }
}

.center-snapshot-section {
  background-color: $color-light;

  .snapshot {
    height: 400px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.right-snapshot-section {
  background-color: $color-white;

  .section-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &__title {
      text-align: left;
    }

    &__message {
      text-align: left;
    }
  }

  .snapshot {
    height: 400px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.strategy {
  background-color: #f6f6f6 !important;
}

.left-snapshot-section {
  background-color: $color-light;

  .section-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    &__title {
      text-align: left;
    }

    &__message {
      text-align: left;
    }
  }

  .snapshot {
    height: 400px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.pricing-section {
  background-color: $color-light;

  .pricing-plan {
    &__header {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;

      h3 {
        font-size: $font-size-lg;
        font-weight: $font-weight-semi-bold;
        text-transform: capitalize;
        margin: 0;
      }
    }

    &__sep {
      position: relative;
      width: 100%;
      height: 3px;
      background-color: $color-primary;
    }

    &__price {
      font-size: $font-size-2xl;
      font-weight: $font-weight-semi-bold;
      text-align: center;
      margin-top: 0.8rem;
      margin-bottom: 0.5rem;
      color: $color-dark;

      .symbol {
        font-size: $font-size-md;
        font-weight: $font-weight-semi-bold;
        color: #aaa;
        margin-right: 0.2rem;
      }

      .users {
        font-size: $font-size-md;
        font-weight: $font-weight-semi-bold;
        color: #aaa;
        margin-left: 0.2rem;
      }
    }

    .buy-btn {
      display: block;

      button {
        width: 100%;
        padding: 0.5rem 0.8rem;
      }
    }

    &__body {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;

      .title {
        margin-top: 2rem;
        margin-bottom: 0.5rem;
        font-weight: $font-weight-semi-bold;
        color: $color-dark;
      }

      .sub-description {
        list-style: none;
        margin: 0;
        padding: 0;

        &__item {
          display: flex;
          align-items: center;
          margin-bottom: 0.5rem;

          &:last-child {
            margin-bottom: 0;
          }
        }

        &__icon {
          --dimension: 20px;
          text-align: center;
          height: var(--dimension);
          width: var(--dimension);
          line-height: var(--dimension);
          color: $color-primary;
          font-size: $font-size;
          font-weight: $font-weight-semi-bold;
          margin-right: 0.5rem;
          border: 2px solid $color-primary;
          border-radius: 50%;
        }

        &__feature {
          font-size: $font-size-md;
          color: $color-dark;
          font-weight: $font-weight-normal;
        }
      }
    }
  }
}

.team-section {
  background-color: $color-dark;

  .section-header__message {
    color: $color-white !important;
  }

  .team-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;

    &__avatar {
      width: 100%;
      height: 250px;
      overflow: hidden;
      border-bottom: 4px solid $color-primary;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
      }
    }

    &__body {
      width: 100%;
      padding: 0rem 1.5rem;
      margin-top: 1.6rem;
    }

    &__name {
      font-size: $font-size-lg;
      font-weight: $font-weight-semi-bold;
      text-transform: capitalize;
      margin-top: 0rem;
      margin-bottom: 0.2rem;
    }

    &__position {
      font-size: $font-size-md;
      font-weight: $font-weight-semi-bold;
      color: $color-primary;
      margin-top: 0.2rem;
      margin-bottom: 1.6rem;
    }
  }
}

.footer-section {
  background-color: #000;

  font-size: $font-size-md;
  color: $color-white;
  padding-top: 1rem;
  padding-bottom: 1rem;
}
