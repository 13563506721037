@import "./Variables.scss";

// Cards
.uk-card {
  // border-radius: $border-radius-lg;
}

.uk-card-outlined {
  border: 1px solid $light-grey-color;
}

.uk-card-muted {
  background-color: $muted-color;
}
