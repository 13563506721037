// font sizes
$font-size-xs: 0.7rem;
$font-size-sm: 0.75rem;
$font-size: 0.8rem;
$font-size-md: 1rem;
$font-size-lg: 1.25rem;
$font-size-xl: 1.5rem;
$font-size-2xl: 1.75rem;
$font-size-3xl: 2.25rem;
$font-size-4xl: 3rem;
$font-size-5xl: 4rem;
$font-size-6xl: 5rem;
$font-size-7xl: 6rem;
$font-size-8xl: 7rem;

// font-weight
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

// border-radius
$border-radius-sm: 0.2rem;
$border-radius-normal: 0.25rem;
$border-radius-md: 0.5rem;
$border-radius-lg: 1rem;

// colors
$color-primary: #3366cc;
$color-primary-alternative: #9b51e0;
$color-secondary: #6c757d;
$color-success: #28a745;
$color-danger: #dc3545;
$color-warning: #ffc107;
$color-info: #17a2b8;
$color-light: #f8f9fa;
$color-white: #fff;
$color-dark: #222;
$color-muted: #f1f2f4;

// screen sizes
$screen-xs: 480px;
$screen-sm: 768px;
$screen-md: 959px;
$screen-lg: 1200px;
