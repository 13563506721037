@import "./Variables.scss";

// Modals
.kit-modal {
  .uk-modal-dialog {
    border-radius: $border-radius-md;
  }

  .uk-modal-title {
    font-size: $font-size-lg;
    font-weight: $font-weight-semi-bold;
  }

  .sub-heading {
    margin-bottom: 0.2em;
    text-transform: uppercase;
  }

  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
  }
}
