@import "./Variables.scss";

.kit-table {
  border-radius: $border-radius-md;

  th {
    font-size: $font-size;
    font-weight: $font-weight-semi-bold;
    text-transform: none;
  }
  td {
    font-size: $font-size;
    font-weight: $font-weight-normal;
    text-transform: none;
  }
}
