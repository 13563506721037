@import "./Variables.scss";

button {
  font-family: "Poppins", sans-serif;
}

// Solid buttons
.kit-btn {
  display: inline-block;
  font-weight: $font-weight-semi-bold;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  transition: box-shadow 0.3s ease-in-out;
  cursor: pointer;

  // Button sizes
  &.kit-btn-sm {
    padding: 0.25rem 0.5rem;
  }
  &.kit-btn-lg {
    padding: 1rem 2rem;
  }

  // Button colors
  &.kit-btn-primary {
    color: $color-white;
    background-color: $color-primary;
    border-color: $color-primary;
    box-shadow: 0 2px 3px rgba($color-primary, 0.4);
    &:hover {
      box-shadow: 0 10px 14px rgba($color-primary, 0.4);
    }
  }
  &.kit-btn-primary-alt {
    color: $color-white;
    background-color: $color-primary-alternative;
    border-color: $color-primary-alternative;
    box-shadow: 0 2px 3px rgba($color-primary-alternative, 0.4);
    &:hover {
      box-shadow: 0 10px 14px rgba($color-primary-alternative, 0.4);
    }
  }
  &.kit-btn-secondary {
    color: $color-white;
    background-color: $color-secondary;
    border-color: $color-secondary;
    box-shadow: 0 2px 3px rgba($color-secondary, 0.4);
    &:hover {
      box-shadow: 0 10px 14px rgba($color-secondary, 0.4);
    }
  }
  &.kit-btn-success {
    color: $color-white;
    background-color: $color-success;
    border-color: $color-success;
    box-shadow: 0 2px 3px rgba($color-success, 0.4);
    &:hover {
      box-shadow: 0 10px 14px rgba($color-success, 0.4);
    }
  }
  &.kit-btn-danger {
    color: $color-white;
    background-color: $color-danger;
    border-color: $color-danger;
    box-shadow: 0 2px 3px rgba($color-danger, 0.4);
    &:hover {
      box-shadow: 0 10px 14px rgba($color-danger, 0.4);
    }
  }
  &.kit-btn-warning {
    color: $color-dark;
    background-color: $color-warning;
    border-color: $color-warning;
    box-shadow: 0 2px 3px rgba($color-warning, 0.4);
    &:hover {
      box-shadow: 0 10px 14px rgba($color-warning, 0.4);
    }
  }
  &.kit-btn-info {
    color: $color-white;
    background-color: $color-info;
    border-color: $color-info;
    box-shadow: 0 2px 3px rgba($color-info, 0.4);
    &:hover {
      box-shadow: 0 10px 14px rgba($color-info, 0.4);
    }
  }
  &.kit-btn-light {
    color: $color-dark;
    background-color: $color-white;
    border-color: $color-white;
    box-shadow: 0 2px 3px rgba($color-dark, 0.4);
    &:hover {
      color: $color-primary;
      box-shadow: 0 10px 14px rgba($color-white, 0.4);
    }
  }
  &.kit-btn-dark {
    color: $color-white;
    background-color: $color-dark;
    border-color: $color-dark;
    box-shadow: 0 2px 3px rgba($color-dark, 0.4);
    &:hover {
      box-shadow: 0 10px 14px rgba($color-dark, 0.4);
    }
  }
}

// Outlined buttons
.kit-btn-outline {
  color: $color-primary;
  font-weight: $font-weight-semi-bold;
  background-color: transparent;
  border: 2px solid $color-primary;
  padding: 0.5rem 1rem;
  outline: none;
  border-radius: 5px;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out,
    color 0.3s ease-in-out;
  cursor: pointer;

  // colors
  &.kit-btn-primary {
    color: $color-primary;
    border-color: $color-primary;
    &:hover {
      color: $color-white;
      background-color: $color-primary;
      box-shadow: 0 10px 14px rgba($color-primary, 0.4);
    }
  }
  &.kit-btn-secondary {
    color: $color-secondary;
    border-color: $color-secondary;
    &:hover {
      color: $color-white;
      background-color: $color-secondary;
      box-shadow: 0 10px 14px rgba($color-secondary, 0.4);
    }
  }
  &.kit-btn-danger {
    color: $color-danger;
    border-color: $color-danger;
    &:hover {
      color: $color-white;
      background-color: $color-danger;
      box-shadow: 0 10px 14px rgba($color-danger, 0.4);
    }
  }
  &.kit-btn-light {
    color: $color-white;
    border-color: $color-white;
    &:hover {
      color: $color-primary;
      background-color: $color-white;
      box-shadow: 0 10px 14px rgba($color-white, 0.4);
    }
  }
}

// Text buttons
.kit-btn-text {
  color: $color-dark;
  font-weight: $font-weight-semi-bold;
  background-color: transparent;
  border: none;
  padding: 0;
  outline: none;
  border-radius: 0;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out, background-color 0.3s ease-in-out,
    color 0.3s ease-in-out, margin 0.3s ease-in-out;

  .kit-btn-text-value {
    transition: margin 0.3s ease-in-out;
  }

  &:hover {
    .kit-btn-text-value {
      margin-left: 5px;
    }
  }

  // colors
  &.kit-btn-primary {
    color: $color-primary;
  }

  &.kit-btn-secondary {
    color: $color-secondary;
  }
}

// Icon buttons
// Text buttons
.kit-btn-icon {
  color: $color-dark;
  font-weight: $font-weight-semi-bold;
  background-color: transparent;
  border: none;
  padding: 0;
  outline: none;
  border-radius: 0;
  cursor: pointer;

  // colors
  &.kit-btn-primary {
    color: $color-primary;
    &:hover {
      color: $color-primary;
    }
  }

  &.kit-btn-secondary {
    color: $color-secondary;
    &:hover {
      color: $color-primary;
    }
  }
}
